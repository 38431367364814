import React, { useEffect } from 'react';

const MyComponent = () => {
  useEffect(() => {
    // Call the widget's global function (exposed via window)
    if (window.renderMyWidget) {
      window.renderMyWidget('widget-container', 'validApiKey123');
    }
  }, []);

  return (
    <div>
      <h1>My React Component</h1>
      <div id="widget-container"></div> {/* Container for the widget */}
    </div>
  );
};

export default MyComponent;
