import React from "react";
import { BsGithub } from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

function Footer() {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-evenly bg-[#0F0416] text-white flex-wrap px-3 py-4">
      <p className="text-sm font-semibold text-center m-2">
        Copyright © 2024 Saola Innovations
      </p>
      <span className="flex items-center justify-center gap-7 m-2">
        <a
          href="https://www.linkedin.com/company/saola-in/"
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://www.instagram.com/saola.innovations?igsh=cWxsNmk3OW9jNDcy"
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          <AiFillInstagram />
        </a>
      </span>
      <span className="flex items-center justify-center gap-7 m-2 text-sm">
        <a href="/privacy" className="text-white hover:text-[#ad50eb] transition duration-300">
          Privacy Policy
        </a>
        <a href="/tnc" className="text-white hover:text-[#ad50eb] transition duration-300">
          Terms & Conditions
        </a>
        <a href="/rar" className="text-white hover:text-[#ad50eb] transition duration-300">
          Return & Refund
        </a>
      </span>
    </div>
  );
}

export default Footer;
