import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-5">
      <img
        className="h-60 lg:h-96 w-auto"
        src="../../images/undraw_page_not_found_re_e9o6.svg"
        alt="404 error"
      />
      <h2 className="text-2xl lg:text-4xl text-gray-800 mt-6">This Page Is Not On The Map.</h2>
      <Link to="/" className="mt-4">
        <button className="btn bg-[#ad50eb] hover:bg-[#942cd6] text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline transition duration-300">
          Back to Home
        </button>
      </Link>
    </div>
  );
};

export default Error;
