import React from "react";
import { FaHandPointRight } from "react-icons/fa";
function WhoIM() {
  return (
    <div className="flex flex-wrap items-center justify-center flex-col lg:flex-row relative">
      <img
        src="./star.jpg"
        alt=""
        className="h-full w-full object-cover opacity-30 absolute bottom-0 z-0"
      />
      <div className="w-full lg:w-1/2 h-1/2 lg:h-full flex items-center justify-center flex-col my-10 lg:gap-10 z-20">
       
        <div className="flex items-center m-auto justify-center w-11/12 lg:w-4/5">
          <span className="flex flex-col gap-3 text-white items-start  text-base justify-center font-thin lg:text-lg">
          <h1 className="flex text-white text-2xl lg:text-4xl gap-2 font-bold">
        Who We<p className="text-[#ad50eb]">Are</p>
        </h1>
            <p className="text-start mt-3 mx-auto md:mx-0">
            At Saola Innovations, we are passionate about transforming ideas into reality. 
            Our team comprises experts in various fields including IoT, Machine Learning, Software Development, 
            and more. With a commitment to excellence and a drive for innovation,
             we strive to deliver top-notch solutions that cater to the evolving needs of our clients.
            </p>
          </span>
          
        </div>

        <div className="flex items-center m-auto justify-center w-11/12 lg:w-4/5">
          <span className="flex flex-col gap-3 text-white items-start  text-base justify-center font-thin lg:text-lg">
          <h1 className="flex text-white text-2xl lg:text-4xl gap-2 font-bold">
          Our <p className="text-[#ad50eb]">Vision</p>
        </h1>
            <p className="text-start mt-3 mx-auto md:mx-0">
            To become a global leader in technological innovations,
             providing solutions that enhance lives and drive businesses forward.

  </p>
          </span>
          
        </div>
        <div className="flex items-center m-auto justify-center w-11/12 lg:w-4/5">
          <span className="flex flex-col gap-3 text-white items-start  text-base justify-center font-thin lg:text-lg">
          <h1 className="flex text-white text-2xl lg:text-4xl gap-2 font-bold">
          Our <p className="text-[#ad50eb]">Mission</p>
        </h1>
            <p className="text-start mt-3 mx-auto md:mx-0">
            To develop innovative, efficient, and reliable technological solutions 
            that meet the needs of our clients and contribute to a sustainable future.


  </p>
          </span>
          
        </div>
      </div>
      
      <div className="w-full lg:w-1/2 h-full flex items-center justify-center z-20">
        <img
          src="about.png"
          alt=""
          className="lg:p-10 object-cover lg:w-3/4 w-full"
        />
      </div>
    </div>
  );
}

export default WhoIM;
