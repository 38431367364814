import React, { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

function DeleteAccount() {
  // State to store the input email ID
  const [email, setEmail] = useState('');

  // Function to handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    // Placeholder for actual delete functionality
    // You would replace this with your actual logic to handle account deletion
    // console.log("Attempting to delete account for:", email);

    // Alerting the user
    alert("We will update and look into it for the account associated with: " + email+" Thank You - Team Saola");

    // Clear the input after submission (optional)
    setEmail('');
  };

  return (
    <div>
   
    <div className="flex items-center justify-center h-screen bg-gray-100">
        
      <form onSubmit={handleSubmit} className="w-full max-w-xs">
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
            Email ID
          </label>
          <input
            type="email"
            id="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Delete Account
          </button>
        </div>
      </form>
    </div>
    <Footer/>
    </div>
  );
}

export default DeleteAccount;
