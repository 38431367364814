import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Project from "./pages/Project";
// import Resume from "./pages/Resume";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AnimeCursor from "./components/AnimeCursor";
import ScrollToTop from "./components/ScrollToTop";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import TAT from './pages/TAT';
import RAR from './pages/RAR';
import DeleteAccount from './pages/deleteAccount';
import TermsOfService from './pages/tc';
import Error from "./pages/Error";
import MyComponent from "./PluhinBtn";
function App() {
  return (
    <div className="bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900">
      {/* <AnimeCursor /> */}
      <Header />
      <ScrollToTop />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />

        <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/tat" element={<TAT />} />
            <Route exact path="/tnc" element={<TermsOfService />} />

            <Route exact path="/rar" element={<RAR />} />
            
            <Route exact path="/deleteAccount" element={<DeleteAccount />} />
      

      </Routes>
      <Footer />      
      <MyComponent/>


    </div>
  );
}

export default App;
