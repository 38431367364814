import React from 'react';

export default function ContactUs() {
  return (
    <div className="bg-[url(../public/home-bg.jpg)] bg-cover bg-center min-h-screen flex flex-col lg:flex-row items-center justify-center p-5">
      <div className="w-full lg:w-1/2 flex items-center justify-center flex-col mt-10 lg:mt-0">
        <div className="flex gap-5 items-center lg:items-start justify-center flex-col text-center lg:text-left">
          <h3 className="flex text-white text-4xl gap-2 items-center">
            Get in Touch!
            <span>
              <img
                src="https://media.tenor.com/ju4PztDv8WcAAAAj/firstrequestrecords-first-request.gif"
                alt="hand"
                className="h-10"
              />
            </span>
          </h3>
          {/* <span className="flex text-white text-4xl lg:text-5xl gap-2 font-bold items-center justify-center">
            We are <p className="text-[#ad50eb]"> Saola Innovations</p>
          </span> */}
          <span className="flex text-[#ad50eb] text-2xl lg:text-3xl gap-2 font-semibold bg-gray-800 h-30 lg:h-52 w-72 lg:w-80 items-center justify-center px-5 rounded-md shadow-xl capitalize text-center">
          <div className="text-white text-center mt-5">
           
          <p className="text-2xl lg:text-3xl font-bold mb-2">Company Details</p>
          <p className="text-xl lg:text-2xl">Phone: +919281443441</p>
          <p className="text-xl lg:text-2xl">Email: info@saola.in</p>
          <br/>
        </div>
          </span>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex items-center justify-center flex-col lg:mt-0 mt-10">
        <form className="bg-white p-8 rounded-md shadow-md w-full lg:w-2/3 mb-10">
          <h2 className="text-2xl lg:text-3xl font-bold mb-6 text-center text-[#ad50eb]">Contact Us</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Your name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Your email address"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              placeholder="Your message"
              rows="4"
            ></textarea>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-[#ad50eb] hover:bg-[#942cd6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
              type="button"
            >
              Send Message
            </button>
          </div>
        </form>
        
      </div>
    </div>
  );
}
